<template>
  <div class="mangerPage">
    <div class="main">
      <div class="top_view">
        <a-button type="primary" class="addBtn" @click="addBannerClick"
          >新建轮播图</a-button
        >
        <div>
          <a-input-search
            class="search_view"
            v-model="searchValue"
            placeholder="请输入Banner名称进行查询"
            enter-button
            allow-clear
            @search="onSearchBanner"
          />
          <a-select
            style="width: 140px; margin-right: 20px;"
            v-model="statusValue"
            placeholder="请选择状态"
            @change="changStatusValue"
          >
            <a-select-option
              v-for="(items, index) in statusList"
              :key="index"
              :value="items.value"
            >
              {{ items.title }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        bordered
        :scroll="{ x: 1400 }"
        :pagination="false"
        :rowKey="record => record.bannerId"
        :loading="tableLoad"
      >
        <template slot="jumpName" slot-scope="text, record">
          <span v-if="record.jumpName == null">-</span>
          <span v-else>{{ record.jumpName }}</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <template v-if="record.status === 1">
            <a-popconfirm
              title="确定禁用该记录吗？小程序端将不可见"
              ok-text="确定"
              cancel-text="取消"
              @confirm="contentStutas(record)"
            >
              <a-tag class="pointer" color="green">
                正常
              </a-tag>
            </a-popconfirm>
          </template>
          <template v-if="record.status === 0">
            <a-popconfirm
              title="确定启用该记录吗？小程序端将可见"
              ok-text="确定"
              cancel-text="取消"
              @confirm="contentStutas(record)"
            >
              <a-tag class="pointer">
                未启用
              </a-tag>
            </a-popconfirm>
          </template>
        </template>
        <template slot="action" slot-scope="text, record, index">
          <div class="action_btn_view">
            <a-space>
              <span v-if="index != 0">
                <a-popconfirm
                  placement="top"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="upFun(record)"
                >
                  <template #title>
                    <p>确定上移该记录吗？</p>
                  </template>
                  <a-button>上移</a-button>
                </a-popconfirm>
              </span>
              <span v-if="index < tableData.length - 1">
                <a-popconfirm
                  placement="top"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="downFun(record)"
                >
                  <template #title>
                    <p>确定下移该记录吗？</p>
                  </template>
                  <a-button>下移</a-button>
                </a-popconfirm>
              </span>
              <span v-if="index != 0">
                <a-popconfirm
                  placement="top"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="topFun(record)"
                >
                  <template #title>
                    <p>确定置顶该记录吗？</p>
                  </template>
                  <a-button>置顶</a-button>
                </a-popconfirm>
              </span>
              <a-button @click="editorFun(record)">编辑</a-button>
              <span>
                <a-popconfirm
                  placement="top"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="deleteFun(record)"
                >
                  <template #title>
                    <p>确定删除该记录吗？</p>
                  </template>
                  <a-button>删除</a-button>
                </a-popconfirm>
              </span>
            </a-space>
          </div>
        </template>
      </a-table>
      <div class="page_warp">
        <a-pagination
          class="pagination"
          v-model="page.current"
          :total="page.total"
          show-less-items
          :defaultPageSize="page.size"
          @change="pageCurrentChange"
        />
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <a-modal
      width="75%"
      v-model="isAdd"
      :title="header_title"
      okText="确定"
      cancelText="取消"
      @cancel="cancel"
      :maskClosable="false"
      :keyboard="false"
      @ok="submitData"
    >
      <div class="modal_content">
        <a-form-model
          layout="horizontal"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 14 }"
          :model="formData"
          :rules="rules"
          ref="contentForm"
        >
          <a-form-model-item label="轮播图类型" prop="location">
            <a-select v-model="formData.bannerType" placeholder="请选择">
              <a-select-option :value="1">
                首页
              </a-select-option>
              <a-select-option :value="2">
                popup
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="轮播图名称" prop="name">
            <a-input
              v-model="formData.name"
              placeholder="请输入"
              :maxLength="50"
            />
          </a-form-model-item>
          <a-form-model-item label="轮播图素材" prop="materials">
            <a-upload
              listType="picture-card"
              class="upload_btn"
              :fileList="coverList"
              :customRequest="uploadCover"
              @preview="handlePreview"
              :remove="delCover"
              accept=".mp4,.bmp,.jpg,.jpeg,png"
            >
              <div v-if="coverList.length < 1">
                <a-icon :type="loading ? 'loading' : 'upload'" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="previewCancel"
              title="预览文件"
            >
              <img
                v-if="formData.materialType == 0"
                alt="example"
                style="width: 100%"
                :src="formData.materials"
              />

              <video v-if="formData.materialType == 1" width="100%" controls>
                <source :src="formData.materials" type="video/mp4" />
                <source :src="formData.materials" type="video/ogg" />
                您的浏览器不支持Video标签。
              </video>
            </a-modal>
            最佳尺寸750*440；<br />
            1.支持BMP/JPG/JPEG/PNG格式图片；图片大小不超过2M；<br />
            2.支持MP4格式视频，视频大小不超过20M
          </a-form-model-item>
          <a-form-model-item label="跳转链接" prop="jump" placeholder="请输入">
            <a-input v-model="formData.jump" :maxLength="50" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <Model
      :showModal="isBannerName"
      :bannerAreaDetail="bannerAreaDetail"
      @cancelBanner="cancelBanner"
      @submitDataArea="submitDataArea"
    />
  </div>
</template>

<script>
import API from "@/request";
import { mixin } from "./banner_common";
import moment from "moment";
import upload from "@/utils/upload";
import { compressImage } from "../../assets/staticData/compressImage";

export default {
  mixins: [mixin],
  name: "mangerPage",
  data() {
    return {
      ...this.data()
    };
  },
  methods: {
    data() {
      return {
        isBannerName: false, //轮播图区域名称显示
        bannerAreaDetail: {}, //轮播图区域信息
        coverList: [], // 上传轮播图列表
        previewVisible: false, //控制首页图预览显示隐藏字段
        loading: false, // 封面图上传加载效果控制字段
        tableLoad: false, // 表格数据加载效果
        previewData: {}, //手机预览传入的数据
        isSubmitInfo: false, //点击新建可能会重复提交数据
        rules: {
          // 表单验证规则
          bannerType: [
            {
              required: true,
              message: "请选择轮播图类型",
              trigger: "change"
            }
          ],
          name: [
            {
              required: true,
              message: "请输入轮播图名称",
              trigger: "blur"
            }
          ],
          materials: [
            {
              required: true,
              message: "请上传轮播图素材",
              trigger: "blur"
            }
          ],
          sort: [
            {
              required: true,
              message: "请输入轮播图顺序",
              trigger: "blur"
            }
          ]
        },
        formData: {
          // 表单数据
          materials: "", //轮播图图片
          jump: "", //轮播图跳转信息
          bannerType: this.typeValue, //轮播图位置
          name: "", //轮播图名称
          sort: "", //轮播图顺序
          type: 1 //轮播图类型
        },
        page: {
          // 表格分页相关
          total: 0,
          current: 1,
          size: 100
        },
        tableData: [], // 表格数据
        changStatus: "", // 表格右上类型筛选绑定字段
        searchValue: "",
        statusList: [
          {
            value: "",
            title: "全部状态"
          },
          {
            value: "1",
            title: "正常"
          },
          {
            value: "0",
            title: "未启用"
          }
        ], //搜索状态
        statusValue: "",
        typeValue: 0,
        columns: [
          {
            title: "Banner名称",
            dataIndex: "name",
            key: "name"
          },
          {
            title: "Banner类型",
            dataIndex: "bannerType"
          },
          {
            title: "展示顺序",
            dataIndex: "sort",
            key: "sort",
            width: 120,
            customRender: (text, record, index) => {
              return index + 1;
            }
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            width: 100,
            scopedSlots: { customRender: "status" }
          },
          {
            title: "修改日期",
            dataIndex: "updateTime",
            key: "updateTime",
            width: 120,
            customRender: record => {
              let aFormat = moment(parseInt(record)).format("YYYY/MM/DD");
              return aFormat;
            }
          },
          {
            title: "操作",
            key: "operation",
            width: 400,
            scopedSlots: { customRender: "action" }
          }
        ],
        isAdd: false, // 控制新增表单弹窗控制字段
        isShowPriview: false
      };
    },
    resetData() {
      Object.assign(this.$data, this.data());
    },
    pageCurrentChange(val) {
      // 分页页码变化
      this.page.current = val;
      this.getList();
    },
    //状态搜索
    changStatusValue(value) {
      this.statusValue = value;
      this.getList();
    },
    //类型搜索
    // changTypeValue(value) {
    //   this.typeValue = value;
    //   this.getList();
    // },
    imgPreviewCancel() {
      this.isImgPreview = false;
    },
    dateFormat(val) {
      return moment(val).format("YYYY年MM月DD日 a HH");
    },
    /**
     * 轮播图预览
     */
    handlePreview() {
      // 轮播图预览方法
      this.previewVisible = true;
    },
    previewCancel() {
      // 轮播图取消预览
      this.previewVisible = false;
    },
    // 轮播图素材上传
    uploadCover(action) {
      const file = action.file;
      if (
        file.type == "image/png" ||
        file.type == "image/bmp" ||
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "video/mp4"
      ) {
        let size = file.size / 1048576;

        if (
          (file.type == "image/png" ||
            file.type == "image/bmp" ||
            file.type == "image/jpeg" ||
            file.type == "image/jpg") &&
          size > 2
        ) {
          this.$message.warning("图片文件不能超过2M");
          return;
        }
        if (file.type == "video/mp4" && size > 20) {
          this.$message.warning("视频文件不能超过20M");
          return;
        }
        let pathName =
          "content/" +
          new Date().valueOf() +
          parseInt(Math.random() * 10000) +
          file.name;
        this.loading = true;
        let config = {
          //width : 400, //默认尺寸为原图尺寸的0.75
          //height : 300,
          //quality : 0.5  //如果不传,默认0.5
        };

        if (file.type == "video/mp4") {
          upload.uploadFile(pathName, file).then(res => {
            this.loading = false;
            this.coverList.push({
              uid: "-1",
              name: "image.png",
              status: "done",
              url: res
            });
            this.formData.materials = this.coverList[0].url;
            this.formData.materialType = 1;
          });
        } else {
          compressImage(file, config).then(result => {
            var compressFile = new File([result], file.name, {
              type: file.type
            });
            upload.uploadFile(pathName, compressFile).then(res => {
              this.loading = false;
              this.coverList.push({
                uid: "-1",
                name: "image.png",
                status: "done",
                url: res
              });
              this.formData.materials = this.coverList[0].url;
              this.formData.materialType = 0;
            });
          });
        }
      } else {
        this.$message.warning("不支持的文件格式");
      }
    },
    /**
     * 修改轮播图名称
     */
    editBannerName() {
      this.header_title = "设置轮播图区域名称";
      this.isBannerName = true;
    },
    /**
     * 新增轮播图
     */
    addBannerClick() {
      this.header_title = "新增轮播图";
      this.cancel();
      this.isAdd = true;
    },
    delCover() {
      // 删除轮播图
      this.coverList = [];
      this.formData.materials = [];
    },
    /**
     * 删除轮播图
     */
    async deleteFun(item) {
      let res = await API.deleteBanner({ bannerId: item.bannerId });

      if (res.data.code === "0000") {
        this.$message.success("删除成功");
        this.getList();
      }
    },
    /**
     * 编辑轮播图
     */
    async editorFun(record) {
      this.header_title = "编辑轮播图";
      this.isAdd = true;
      this.contentLoading = true;
      let res = await API.getBannerById({ bannerId: record.bannerId });

      if (res.data.code === "0000") {
        // 组装数据
        this.coverList = [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: res.data.data.materials
          }
        ];

        this.contentLoading = false;
        this.formData.bannerId = res.data.data.bannerId;
        this.formData.bannerType = res.data.data.bannerType;
        this.formData.name = res.data.data.name;
        this.formData.materials = res.data.data.materials;
        this.formData.materialType = res.data.data.materialType;
        this.formData.jump = res.data.data.jump;
      }
    },
    //名称搜索 轮播图
    onSearchBanner(value) {
      this.getList();
    },
    /**
     * 弹出框提交数据
     */
    async submitData(val) {
      let validateJump;
      // 组装提交数据
      this.$refs.contentForm.validate(async valid => {
        if (valid) {
          let res;

          let message;

          if (!this.formData.bannerId) {
            if (this.isSubmitInfo) {
              // this.$message.warning("新增中，请勿重复提交！");
              return;
            }
            this.isSubmitInfo = true;
            res = await API.addBanner(this.formData).catch(err => {
              this.isSubmitInfo = false;
            });
            message = "新增成功";
          } else {
            res = await API.editBanner(this.formData);
            message = "修改成功";
          }
          if (res.data.code === "0000") {
            this.$message.success(message);
            this.isAdd = false;
            this.formData = {
              materials: "",
              jump: "",
              name: "",
              bannerType: 1
            };
            this.coverList = [];
            this.getList();
          }
        }
      });
    },
    /**
     * 轮播图区域配置提交
     */
    async submitDataArea(val) {
      let requestData = {
        areaName: val.areaName,
        status: val.status,
        location: this.typeValue
      };
      let res = await API.addBannerArea(requestData);
      if (res.data.code === "0000") {
        this.$message.success("修改成功");
        this.isBannerName = false;
      }
    },
    handleChange() {},
    async contentStutas(record) {
      let value = record.status === 1 ? 0 : 1;
      let res = await API.updateBannerStatus({
        bannerId: record.bannerId,
        status: value
      });
      if (res.data.code === "0000") {
        this.getList();
      }
    },
    cancel() {
      if (this.$refs.contentForm != undefined) {
        this.$refs.contentForm.resetFields();
      }
      this.formData = {
        // 表单数据
        materials: "", //轮播图图片
        jump: "", //轮播图跳转信息
        name: "" //轮播图名称
      };
      this.coverList = [];
      this.isSubmitInfo = false;
    },
    cancelBanner() {
      this.isBannerName = false;
      let data = sessionStorage.getItem("bannerAreaDetail") || "";
      this.bannerAreaDetail = JSON.parse(data);
    },
    // 获取表格数据
    async getList(
      keyword = this.searchValue,
      status = this.statusValue,
      current = this.page.current,
      size = this.page.size,
      location = this.typeValue
    ) {
      this.tableLoad = true;
      let res = await API.getBannerPageList({
        keyword,
        status,
        current,
        size,
        location
      });

      if (res.data.code === "0000") {
        this.tableLoad = false;
        let data = res.data.data;
        this.tableData = data.records;
        this.page = {
          // 设置分页信息
          current: data.current,
          total: data.total,
          size: data.size
        };
      }
    }
  },
  mounted() {
    this.getList(); // 请求表格数据
  }
};
</script>

<style lang="less" scoped>
/deep/.has-error .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #f5222d !important;
}
.loading_warp {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .loading_text {
    text-align: center;
  }
}
.picView {
  max-width: 350px;
  max-height: 200px;
}
.videoPlater {
  outline: none;
  max-width: 300px;
  max-height: 200px;
}
.pageHead {
  background: white;
}

.main {
  margin: 24px;
  background: #ffffff;
  padding: 24px;
}

.newLotsBtn {
  margin-bottom: 16px;
}
.modal_content {
  max-height: 600px;
  overflow: auto;
}
.search_View {
  width: 230px;
}

.tab_top_view {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.content_warp {
  display: flex;
  justify-content: space-between;
  .block_warp {
    margin-top: 10px;
    // border: 1px solid #cccccc;
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    // padding: 15px 10px;
    display: flex;
  }
}
.top_view {
  display: flex;
  justify-content: space-between;
  height: 48px;
}
.search_view {
  width: 280px;
  margin-right: 20px;
}
.page_warp {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.titleDiv {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    margin-right: 10px;
  }
  .titleInput {
    width: 307px;
    margin-right: 10px;
  }
}
</style>
