import API from "@/request";
export const mixin = {
  data() {
    return {};
  },
  methods: {
    async moveFun(record, sortType) {
      try {
        await API.moveBanner({
          location: record.location,
          id: record.bannerId,
          sortType: sortType
        });
        this.getList();
      } catch (e) {
        console.log(e);
      }
    },
    topFun(record) {
      this.moveFun(record, 3);
    },
    downFun(record) {
      this.moveFun(record, 2);
    },
    upFun(record) {
      this.moveFun(record, 1);
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.resetData();
      vm.typeValue = vm.$route.meta.type;
      vm.getList();
    });
  }
};
